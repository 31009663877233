.navigation-bar-bg {
    height: 70px;
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    z-index: 5;
}

.navigation-bar-list {
    padding-left: 0px;
    list-style-type: none;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.navigation-bar-list li {
    text-align: center;
}

.navigation-menu-icon {
    min-height: 31px;
}

.navigation-menu {
    position: relative;
}

.navigation-menu a {
    text-decoration: none;
}

.navigation-menu-text {
    text-decoration: none;
    color: var(--green);
    font-weight: 600;
    font-size: 13px;
    margin-top: 2px;
}

.navigation-active .navigation-menu-text {
    color: var(--orange);
}

.navigation-active .navigation-menu-icon svg path {
    fill: #BF4418 !important;
}

.navigation-active .navigation-menu-icon svg path {
    fill: #BF4418 !important;
}

.navigation-small-number {
    right: -5px;
    top: -5px !important;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

@media only screen and (max-width: 401px) {
    .navigation-menu-text {
        font-size: 12px;
    }
}

@media only screen and (max-width: 350px) {
    .navigation-menu-text {
        font-size: 10px;
    }

    .navigation-menu-icon svg {
        max-width: 28px !important;
    }
}