.people-header-tabs-bg {
    padding: 7px 15px;
    background-color: var(--dark-green);
    border-radius: 5px;
}

.people-header-tabs-bg ul {
    justify-content: space-between;
}

.people-header-tabs-bg ul a {
    color: white !important;
    padding: 0.4rem 0.6rem !important;
    font-size: 14px;
}

.people-header-tabs-bg ul .active {
    background-color: var(--orange) !important;
}

.people-search .input-group-text {
    background-color: var(--dark-green);
    border: none !important;
    padding-right: .1rem !important;
}

.people-search .form-control,
.people-search .form-control:focus {
    background-color: var(--dark-green);
    border: none !important;
    height: 40px;
    color: white;
}

.people-search .form-control:focus {
    box-shadow: none !important;
}

.people-search .form-control::placeholder {
    color: #BFCACC !important;
}