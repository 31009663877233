.contracts-button-prev {
    border-radius: 4px;
    background-color: var(--orange);
    padding: 4px 10px;
    color: white;
    border: none;
    font-size: 14px;
    font-weight: 500;
}

.contracts-button-prev img {
    margin-right: 5px;
    max-width: 6px;
}

.contracts-button-next {
    border-radius: 4px;
    background-color: var(--orange);
    padding: 4px 10px;
    color: white;
    border: none;
    font-size: 14px;
    font-weight: 500;
}

.contracts-button-next img {
    margin-right: 5px;
    max-width: 6px;
}

.contracts-download-btn {
    border: none;
    height: 60px;
    width: 100%;
    margin-top: 15px;
    background-color: var(--orange);
    color: white;
    font-weight: 600;
    border-radius: 5px;
}

.contracts-download-btn img {
    margin-right: 10px;
}

.people-detail-card-phone {
    background-color: var(--orange);
    border: none;
    border-radius: 5px;
    padding: 10px;
}

.people-detail-card-mail {
    background-color: var(--orange);
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-left: 5px;
}

.messages-detail-button button {
    background-color: var(--orange);
    padding: .3rem .7rem;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
}

.messages-detail-button button img {
    width: 13px;
    margin-right: 5px;
}

.btn-in-icon {
    margin-right: 5px;
}

.btn-modal-st {
    width: 100%;
}