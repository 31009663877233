/* chat_box */

.chat_box {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat_box>* {
    padding: 16px;
}


/* chat-box-chat-box-head */

.chat-box {
    height: 72%;
    margin-bottom: 72px;
}

.chat-box .chat-body {
    height: 100%;
    overflow: auto;
}

.chat-box-head .name {
    color: #2a2a2a;
    font-weight: 600;
}

.chat-box-head {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
}

.chat-box-head .user {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.chat-box-head .user .avatar {
    margin-right: 8px;
}

.chat-box-head .user .avatar img {
    display: block;
    border-radius: 50%;
}

.chat-box-head .bar_tool {
    display: flex;
}

.chat-box-head .bar_tool i {
    padding: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* chat-body */

.bubble p {
    margin-bottom: 0px;
}

.chat-body {
    flex-grow: 1;
    padding: 15px 10px;
    background-color: #eee;
}

.chat-body .bubble {
    display: inline-block;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 8px;
}

.chat-body .bubble p {
    color: #f9fbff;
    font-size: 15px;
    text-align: left;
    line-height: 1.4;
}

.chat-body .incoming {
    text-align: left;
    margin-bottom: 10px;
}

.chat-body .incoming .bubble {
    background-color: #8e8e8e;
}

.chat-body .outgoing {
    text-align: right;
}

.chat-body .outgoing .bubble {
    background-color: #bf4418;
}


/* foot */

.foot {
    display: flex;
    align-items: center;
    height: 52px;
    background-color: white;
    padding: 0px 10px;
    border-radius: 0px 0px 10px 10px;
}

.foot .msg {
    height: 44px;
    border: none;
    resize: none;
    flex-grow: 1;
    padding-top: 11px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #eee;
    border-radius: 5px;
    margin-left: 8px;
    margin-right: 8px !important;
}
.foot .msg:focus{
    box-shadow: unset !important;
   border-color: #e5e5e5;
}
.foot .msg::placeholder {
    font-size: 14px;
    font-weight: 500;
}

.foot .msg {
    font-size: 15px;
    font-weight: 500;
}
.foot-file-btn{
    border: none;
    background-color: transparent;
    padding: 0px;
    font-size: 15px;
   
}
.foot-file-btn span{
    color: #002d35;
    font-size: 18px;
}
.foot .send-button {
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    border: none;
    background-color: #002d35;
    width: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send-button img {
    width: 17px;
}

@keyframes bounce {
    50% {
        transform: translate(0, 5px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.ellipsis {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #b7b7b7;
}

.dot_1 {
    /* animation: name duration timing-function delay iteration-count */
    animation: bounce 0.8s linear 0.1s infinite;
}

.dot_2 {
    animation: bounce 0.8s linear 0.2s infinite;
}

.dot_3 {
    animation: bounce 0.8s linear 0.3s infinite;
}

@media only screen and (max-height: 667px) {
    .chat-body {
        height: 100%;
    }

    .chat-box {
        height: 60%;
    }
}

.bubble-time {
    font-size: 13px;
    font-weight: 400;
    color: #e2e2e2;
}

.bubble-alt{
    margin-top: 3px;
}
.bubble-alt-flex{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: -2px;
}
.incoming .bubble-alt-flex{
    justify-content: start;
}
.bubble-alt-check svg {
    width: 20px;
    margin-left: 5px;
}
.bubble-alt-name{
    font-size: 14px;
    color: white;
}
.read-icon path {
    fill: #34B7F1 !important;
}
.not-read-icon path {
    fill: #e2e2e2 !important;
}