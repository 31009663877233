.select-date-bg .input-group .input-group-text {
    background-color: #002D35 !important;
    border: none;
    height: 45px;
    padding-right: 0px !important;
}

.select-date-bg .input-group .input-group-text img {
    width: 20px !important;
}

.select-date-bg .input-group .form-control {
    background-color: #002D35 !important;
    border: none;
    height: 45px;
    color: white;
}

.select-date-bg .input-group .form-control::placeholder {
    color: #BFCACC !important;
    font-weight: 500 !important;
}

.select-date-bg .input-group input[type="date"]::-webkit-inner-spin-button,
.select-date-bg .input-group input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 20px;
    color: white !important;
    filter: invert(1);
}

.date-card-head {
    font-size: 15px;
    font-weight: 600;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
}

.date-card-days-bold {
    font-weight: 600;
    font-size: 15px;
    margin-left: 10px
}

.date-card-days-light {
    font-weight: 400;
    min-width: 125px;
    display: inline-table;
    font-size: 15px;
}

.date-card-text {
    color: white;
    font-size: 15px;
    margin-bottom: 3px;
}

.date-accordion button {
    color: white;
    border-radius: 5px !important;
    background-color: #00343d !important;
}

.date-accordion .accordion-item {
    border: none !important;
    margin-top: 15px;
    background-color: transparent !important;
}

.date-accordion button:focus {
    box-shadow: unset !important;
}

.accordion{
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important
}

.accordion-button:not(.collapsed) {
    color: white !important;
}

.date-accordion .accordion-body {
    background-color: #002D35;
}

.date-accordion-body-span {
    font-size: 15px;
    font-weight: 500;
    color: white;
}