.main-body-bg {
    padding-top: 60px;
    background-color: var(--green);
}

.main-header-head {
    padding: 0px 15px;
    background-color: #002D35;
    height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
}

.main-header-text {
    margin-left: auto;
}

.main-header-text h1 {
    margin-bottom: 0px;
    color: white;
    font-size: 22px;
    font-weight: 600;
}

.main-header-left {
    width: 35px;
    height: 35px;
    background-color: var(--green);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-header-left a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-header-right {
    width: 35px;
    height: 35px;
    margin-left: auto;
}

.main-body-head {
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.main-bg {
    margin-bottom: 100px;
}


.holidays-card-date {
    color: white;
    font-size: 13px;
    display: flex;
}

.holidays-card-date-light {
    font-weight: 400;
    min-width: 100px;
    display: block;
}

.holidays-card-date-bold {
    font-weight: 600;
    margin-left: 10px;
}

.contracts-card-button a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 185px;
    border-radius: 6px;
    font-size: 13px;
    color: white !important;
    font-weight: 500;
    background-color: var(--orange);
    margin-left: auto;
    text-decoration: none !important;
}

.contracts-card-button a img {
    margin-right: 5px;
}

.date-label {
    font-size: 14px;
    color: white;
}

.holidays-card-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 185px;
    border-radius: 6px;
    font-size: 13px;
    color: white !important;
    font-weight: 500;
    background-color: var(--orange);
    text-decoration: none !important;
}

.holidays-card-button img {
    margin-right: 5px;
}

.holidays-card-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100px;
    border-radius: 6px;
    font-size: 13px;
    color: white !important;
    font-weight: 500;
    background-color: #002d35;
    margin-left: 10px;
    text-decoration: none !important;
}

.holidays-card-delete img {
    margin-right: 5px;
    max-width: 20px;
}

.holidays-card-buttons {
    display: flex;
}

.contracts-all-card {
    background-color: var(--dark-green);
    padding: 10px;
    border-radius: 10px;
    color: white;
    text-align: center;
}

.contracts-all-card h6 {
    font-weight: 500;
    font-size: 14px;
}

.contracts-all-card span {
    font-weight: 700;
    font-size: 20px;
}