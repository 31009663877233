@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root {
    --green: #00444F;
    --dark-green: #00343D;
    --orange: #BF4418;
}

body {
    font-family: Montserrat !important;
    background-color: var(--green) !important;
}

.main-body {
    background-color: var(--green);
}