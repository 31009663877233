@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/react-modern-drawer/dist/index.css";
@import "../node_modules/fontawesome-free/css/fontawesome.css";
@import "../node_modules/fontawesome-free/css/all.css";
@import "../src/assets/css/style.css";
@import "../src/assets/css/login.css";
@import "../src/assets/css/navigation-bar.css";
@import "../src/assets/css/navbar.css";
@import "../src/assets/css/home.css";
@import "../src/assets/css/main.css";
@import "../src/assets/css/card.css";
@import "../src/assets/css/button.css";
@import "../src/assets/css/people.css";
@import "../src/assets/css/pagination.css";
@import "../src/assets/css/statistics.css";
@import "../src/assets/css/chat.css";

:root {
    --bs-primary: #00444F !important;
    --bs-primary-rgb: 0, 68, 79 !important;
    --bs-secondary: #BE4415 !important;
    --bs-secondary-rgb: 190, 68, 21 !important;
}