.navbar {
    display: flex;
    align-items: center;
    position: fixed;
    background-color: #00444f;
    border-bottom: 1px solid #002d35;
    width: 100%;
    top: 0;
    padding: 0px 15px;
    height: 60px;
    z-index: 4;
}

.navbar-right {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.navbar-profile {
    margin-right: 15px;
}

.navbar-profile .dropdown button {
    color: white;
    padding: 0px !important;
    font-size: 17px;
    display: flex;
    align-items: center;
}

.navbar-profile .dropdown button img {
    margin-right: 4px;
}

.navbar-profile .dropdown button:focus {
    box-shadow: unset !important;
}

.toggle-menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    outline: none;
    padding: 0;
    pointer-events: initial;
    position: relative;
    vertical-align: middle;
    z-index: 3;
}

.toggle-menu span {
    background-color: white;
    content: "";
    display: block;
    height: 3px;
    left: calc(62% - 13px);
    position: absolute;
    top: calc(50% - 1px);
    transform-origin: 50% 50%;
    transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out, transform 0.2s linear;
    width: 31px;
}

.toggle-menu span:before,
.toggle-menu span:after {
    background-color: white;
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    transform-origin: 50% 50%;
    transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out, transform 0.2s linear;
    width: 31px;
}

.toggle-menu span:before {
    top: 9px;
}

.toggle-menu span:after {
    top: -9px;
}

.toggle-menu.active span {
    background-color: transparent;
    transition: background 0.2s ease-out;
}

.toggle-menu.active span:before,
.toggle-menu.active span:after {
    transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
}

.toggle-menu.active span:before {
    top: 0;
    transform: rotate3d(0, 0, 1, -45deg);
}

.toggle-menu.active span:after {
    top: 0;
    transform: rotate3d(0, 0, 1, 45deg);
}

.mobile-nav-open .menu-btn span {
    transform: scaleX(0);
}

.mobile-nav-open .menu-btn:before {
    transform: rotate(45deg);
    border-color: #fff;
}

.mobile-nav-open .menu-btn:after {
    transform: rotate(-45deg);
    border-color: #fff;
}

.wrapper {
    position: fixed;
    left: -100%;
    height: calc(100dvh - 130px);
    width: 70%;
    background-color: #00444f;
    transition: all 0.6s ease-in-out;
    z-index: 4;
}

.wrapper-open {
    left: 0;
}

.wrapper-opacity {
    position: fixed;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background-color: #000000;
    opacity: .8;
    transition: all .4s ease-in-out;
    z-index: 3;
}

.wrapper-opacity-open {
    left: 0;
}

.wrapper-body {
    padding: 30px 15px;
}

.wrapper-list {
    margin-top: 40px;
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.wrapper-menu-bg {
    background-color: var(--dark-green);
    padding: 0px 15px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 48px;
}

.wrapper-list a {
    text-decoration: none;
}

.wrapper-menu-text {
    font-weight: 600;
    color: white;
    font-size: 18px;
}

.wrapper-menu-circle {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: white;
    border-radius: 50%;
}

.wrapper-menu-circle img {
    width: 10px;
}

.wrapper-list li {
    padding: 10px 0px;
}