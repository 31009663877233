.folder-card-bg {
    margin-top: 15px;
    background-color: var(--dark-green);
    border-radius: 6px;
}

.folder-card-list {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.folder-card-list li {
    border-bottom: 1px solid #8A9FA2;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.folder-card-list li a {
    text-decoration: none;
}

.folder-card-list li a img {
    width: 22px;
}

.folder-card-list li a span {
    font-size: 16px;
    color: white;
    font-weight: 500;
    margin-left: 20px;
}

.folder-card-list-bottom {
    border-bottom: 0px solid transparent !important;
}

.main-card-head {
    color: white;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.main-card-button {
    float: right;
    border: none;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--dark-green);
    border-radius: 5px;
    height: 32px;
    padding: 0px 10px;
    color: white;
    margin-left: auto;
}

.notes-card-bg {
    border-left: 7px solid var(--orange);
    border-radius: 5px;
    background-color: var(--dark-green);
    padding: 12px 15px;
    margin-top: 10px;
}

.notes-card-head {
    display: flex;
    align-items: center;
}

.notes-card-text {
    font-size: 15px;
    color: white;
    font-weight: 500;
}

.notes-card-date {
    margin-left: auto;
    font-size: 13px;
    color: white;
    font-weight: 600;
}

.notes-card-alt {
    font-size: 14px;
    font-weight: 400;
    margin-top: 3px;
    color: white;
}

.contracts-card {
    background-color: var(--dark-green);
    border-radius: 5px;
    margin-top: 10px;
}

.contracts-card-header {
    padding: 10px 15px;
    border-bottom: 1px solid #8A9FA2;
}

.contracts-card-header {
    display: flex;
}

.contracts-card-icon {
    width: 40px;
    height: 36px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--orange);
}

.contracts-card-icon img {
    width: 20px;
}

.contracts-card-head {
    font-size: 15px;
    font-weight: 600;
    color: white;
    margin-top: auto;
    margin-left: 15px;
    margin-bottom: auto;
}

.contracts-card-alt {
    padding: 10px 15px;
}

.contracts-card-date {
    color: white;
    font-size: 13px;
}

.contracts-card-date-light {
    font-weight: 400;
}

.contracts-card-date-bold {
    font-weight: 600;
    margin-left: 10px;
}

.contracts-card-desc {
    color: white;
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
}

.people-card-bg {
    margin-top: 15px;
    background-color: var(--dark-green);
    border-radius: 6px;
}

.people-card-list {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.people-card-list li {
    border-bottom: 1px solid #8A9FA2;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.people-card-list li a {
    text-decoration: none;
}

.people-card-list li a img {
    width: 25px;
}

.people-card-list li a span {
    font-size: 15px;
    color: white;
    font-weight: 500;
    margin-left: 10px;
}

.people-card-list-bottom {
    border-bottom: 0px solid transparent !important;
}

.people-card-info-span {
    position: relative;
    top: -3px;
    font-size: 12px !important;
    color: #9CADB0 !important;
    font-weight: 500;
    margin-left: 10px;
}

.people-card-group-list {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.people-card-group-list li {
    border-bottom: 1px solid #8A9FA2;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.people-card-group-list li a {
    text-decoration: none;
    width: 100%;
}

.people-card-group-list li a span {
    font-size: 15px;
    color: white;
    font-weight: 500;
    float: left;
}

.people-card-group-list li a .groups-number {
    float: right !important;
    margin-right: 15px;
}

.people-detail-card-bg {
    background-color: var(--dark-green);
    border-radius: 5px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
}

.people-detail-card-text {
    margin-left: 10px;
}

.people-detail-card-text-name {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
    color: white;
}

.people-detail-card-text-alt {
    font-weight: 400;
    color: white;
    font-size: 13px;
    margin-top: -2px;
}

.people-detail-card-img img {
    width: 38px;
}

.people-detail-card-buttons {
    margin-left: auto;
}

.messages-card-bg {
    background-color: var(--dark-green);
    border-radius: 5px;
    padding: 5px 0px;
}

.messages-card-list {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.messages-card-list li {
    border-bottom: 1px solid #8A9FA2;
    display: flex;
    align-items: center;
    height: 75px;
    padding-left: 15px;
    padding-right: 15px;
}

.messages-card-list li a {
    text-decoration: none;
}

.messages-card-list-bottom {
    border-bottom: 0px solid transparent !important;
}

.messages-card-div-bg {
    display: flex;
    align-items: center;
}

.messages-card-head {
    font-size: 14px;
    font-weight: 600;
    color: white;
    margin-bottom: 2px;
}

.messages-card-div-left {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    background-color: var(--orange);
}

.messages-card-alt {
    margin-top: -2px;
}

.messages-card-alt .light {
    color: white;
    font-size: 13px;
    font-weight: 400;
    margin-right: 5px;
}

.messages-card-alt .bold {
    color: white;
    font-size: 13px;
    font-weight: 600;
    margin-right: 5px;
}

.messages-detail-card-bg {
    background-color: var(--dark-green);
    padding: 15px 15px;
    border-radius: 5px;
}

.messages-detail-head {
    font-size: 15px;
    font-weight: 600;
    color: white;
    margin-bottom: 10px;
}

.messages-detail-desc {
    font-size: 13px;
    font-weight: 400;
    color: white;
    margin-bottom: 15px;
}

.messages-detail-alt-span {
    margin-top: -5px;
}

.messages-detail-alt-span .light {
    color: white;
    font-size: 13px;
    font-weight: 400;
    margin-right: 5px;
}

.messages-detail-alt-span .bold {
    color: white;
    font-size: 13px;
    font-weight: 600;
    margin-right: 5px;
}

.messages-detail-button {
    margin-top: 20px;
}