.pagination-card-bg {
    margin-top: 20px;
}

.pagination-card-bg nav ul {
    justify-content: center;
}

.pagination-card-bg nav ul li .page-link {
    background-color: var(--dark-green);
    border-color: #00171C;
    color: white;
    font-size: 14px;
    padding: 0.25rem 0.65rem !important;
}

.pagination-card-bg nav ul li .page-link:focus {
    box-shadow: unset !important;
    background-color: #00171C !important;
    color: white !important;
    border-color: #00171C !important;
}

.pagination-card-bg nav ul .active .page-link {
    background-color: var(--orange) !important;
    border-color: #00171C !important;
}

.navbar-profile .dropdown-menu[data-bs-popper] {
    left: unset !important;
    right: 0 !important;
    min-width: 200px;
}

.navbar-profile .dropdown-menu {
    padding: 0px !important;
    border-top: 8px solid var(--orange);
    border-radius: 8px !important;
}

.navbar-profile .dropdown-menu li img {
    margin-right: 12px;
}

.navbar-profile .dropdown-menu li .page-link {
    font-weight: 600;
    font-size: 14px;
}

.navbar-profile .dropdown-menu li {
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #8A9FA2;
}

.navbar-profile .dropdown-menu .dropdown-alt {
    border-bottom: 0px solid !important;
}

.navbar-profile .dropdown-menu .dropdown-alt a {
    color: var(--orange);
}