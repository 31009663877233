.splash-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    max-height: 40vh; 
}

.splash-header {
    position: absolute;
    top: 0;
}

.splash-bg {
    height: 100dvh;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.splash-main {
    z-index: 1;
    position: relative;
    height: 400px;
}

.splash-main-button {
    margin-top: auto;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 100px;
}

.splash-main-logo img {
    max-width: 100%;
}

.splash-main {
    padding: 12px 15px;
}

.splash-main-button button {
    border: none;
    color: white;
    font-size: 17px;
    font-weight: 500;
    width: 300px;
    height: 50px;
    border-radius: 20px;
    background-color: var(--green);
    font-weight: 600;
}

.login-bg {
    height: -webkit-fill-available;
    width: 100%;
    display: flex;
    justify-content: center;
}

.login-main {
    margin-top: 170px;
    margin-bottom: 150px;
    position: relative;
    z-index: 100;
    padding: 0px 15px;
    max-height: 570px;
}

.login-main-logo img {
    max-width: 100%;
}

.login-main-button button {
    border: none;
    color: white;
    font-size: 17px;
    width: 300px;
    height: 50px;
    border-radius: 20px;
    background-color: var(--green);
    font-weight: 600;
}

.login-form {
    margin-top: 30px;
}

.login-head-text {
    text-align: center;
}

.login-head-text h1 {
    font-weight: 700;
    font-size: 35px;
    color: black;
}

.login-head-text p {
    color: #919191;
    font-weight: 500;
}

.login-username {
    margin-top: 20px;
}

.login-username input {
    height: 52px;
    border-radius: 40px;
    color: #8D8D8D;
    border: 1px solid #8D8D8D;
    background-color: #F1F1F1;
}

.login-username input::placeholder {
    font-weight: 500;
    font-size: 15px;
    color: #8D8D8D;
    padding-left: .5rem !important;
}

.login-password {
    margin-top: 15px;
}

.login-password input {
    height: 52px;
    border-radius: 40px;
    color: #8D8D8D;
    border: 1px solid #8D8D8D;
    background-color: #F1F1F1;
}

.login-password input::placeholder {
    font-weight: 500;
    font-size: 15px;
    color: #8D8D8D;
    padding-left: .5rem !important;
}

.login-form-alt {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.login-forgot {
    margin-left: auto;
}

.login-forgot a {
    color: #8D8D8D;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}

.login-remember .form-check label {
    color: #8D8D8D;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    top: 3;
}

.login-remember .form-check-input {
    height: 17px;
    width: 17px;
    background-color: #F1F1F1;
    border: none;
    transition: all .3s;
}

.login-remember .form-check-input:focus {
    box-shadow: unset !important;
}

.form-check-input:checked[type=checkbox] {
    background-image: url(../images/icons/chech-icon.svg);
    background-size: 11px;
    transition: all .3s;
}

.form-check-input:checked[type=checkbox] {
    background-color: #BF4418;
    transition: all .3s;
}
@media only screen and (max-height: 675px) {
    .login-head-text h1{
        font-size: 30px;
    }
    .login-main-logo{
        max-width: 300px;
    }
    .login-head-text p{
        font-size: 15px;
    }
    .splash-bottom{
        max-height: 28vh;
    }
    .splash-header{
        max-width: 245px;
    }
  }
