.home-bg {
    background-color: var(--green);
    padding: 45px 15px;
    height: 100%;
    min-height: calc(100dvh - 130px);
    padding-bottom: 80px;
    display: flex;
    align-items: center;
}
.home-alt-bg{
    width: 100%;
}
.home-logo {
    text-align: center;
}
.swal2-title{
    font-size: 20px !important;
}
.punch-shift-form{
    overflow: hidden;
}
.home-head {
    margin-top: 25px;
}
.text-date-head{
    font-size: 22px;
}
.home-head h1 {
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 25px;
}

.home-punch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.punch-big-round {
    width: 215px;
    height: 215px;
    background-color: #638D93;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.punch-middle-round {
    width: 190px;
    height: 190px;
    background-color: #9EB9BC;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.punch-small-round {
    width: 166px;
    height: 166px;
    border: 11px solid var(--orange);
    background: linear-gradient(90deg, rgba(0, 99, 104, 1) 0%, rgba(0, 67, 75, 1) 48%, rgba(0, 41, 50, 1) 100%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.punch-small-round span {
    font-weight: 700;
    color: white;
    font-size: 29px;
    text-align: center;
    letter-spacing: 0px;
    line-height: 35px;
    position: relative;
    top: 5px;
    max-width: 144px;
}

.home-punch:hover .punch-big-round {
    transition: all .3s;
    width: 230px;
    height: 230px;
}

.home-punch:hover .punch-middle-round {
    transition: all .3s;
    width: 205px;
    height: 205px;
}

.home-punch:hover .punch-small-round {
    transition: all .3s;
    width: 189px;
    height: 189px;
}

.home-time {
    background-image: url(../images/main/home-time-effect.png);
    background-repeat: no-repeat;
    background-position: right;
    margin-top: 30px;
    background-color: #00343D;
    height: 90px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-self: center;
    padding: 0px 10px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 11px -5px rgba(8, 8, 8, 1);
    -moz-box-shadow: 0px 0px 11px -5px rgba(8, 8, 8, 1);
    box-shadow: 0px 0px 11px -5px rgba(8, 8, 8, 1);
}

.home-time-span {
    color: white;
    margin-left: auto;
    margin-right: auto;
    font-weight: 700;
    font-size: 35px;
}

.home-take {
    margin-top: 10px;
}

.home-take button {
    border: none;
    width: 100%;
    transition: all .3s;
    height: 90px;
    background-repeat: no-repeat;
    background-position: left;
    background-image: url(../images/main/home-time-effect-l.png);
    background-color: var(--orange);
    -webkit-box-shadow: 0px 0px 11px -5px rgba(8, 8, 8, 1);
    -moz-box-shadow: 0px 0px 11px -5px rgba(8, 8, 8, 1);
    box-shadow: 0px 0px 11px -5px rgba(8, 8, 8, 1);
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.home-take button img {
    margin-right: 10px;
}

.home-take button:hover {
    transition: all .3s;
    background-color: #A8380F;
}

@media (max-height: 760px) {
    .home-logo img {
        max-height: 60px;
    }
    .home-head h1 {
        font-size: 22px;
    }
    .punch-big-round {
        width: 200px;
        height: 200px;
    }
    .punch-middle-round {
        width: 175px;
        height: 175px;
    }
    .punch-small-round {
        width: 151px;
        height: 151px;
    }
    .punch-small-round span {
        font-size: 25px;
        line-height: 28px;
    }
    .home-time {
        height: 80px;
    }
    .home-take button {
        height: 80px;
    }
}